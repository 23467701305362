var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "ma-4" },
    [
      _c(
        "v-card-title",
        { staticClass: "pl-0 pr-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("TableSearch", {
                    attrs: {
                      label: "Pesquisar por descrição ou código",
                      id: "searchBarRubri",
                      disabled: _vm.loading,
                    },
                    on: {
                      input: _vm.onInputTableRubricaSearch,
                      "click:clear": _vm.onClearTableRubricaSearch,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-right", attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs, value }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      style: !value
                                        ? `border:1px solid #e7e7fa`
                                        : `border: 1px solid primary`,
                                      attrs: {
                                        outlined: "",
                                        color: "success",
                                        elevation: "0",
                                        id: "btnDownload",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticStyle: {
                                        "font-size": "18px",
                                        "font-weight": "500",
                                        "margin-right": "8px",
                                      },
                                      attrs: {
                                        color: "success",
                                        id: "idDownload",
                                      },
                                    },
                                    [_vm._v("mdi-download-outline")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        "font-weight": "400",
                                        "letter-spacing": "0em",
                                        "font-family": "'Roboto'",
                                      },
                                      attrs: { id: "btnexport" },
                                    },
                                    [_vm._v("Exportar")]
                                  ),
                                  _c("v-divider", {
                                    staticClass: "mx-2",
                                    staticStyle: { height: "24px" },
                                    attrs: { vertical: "" },
                                  }),
                                  !value
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "success" } },
                                        [_vm._v("mdi-chevron-down")]
                                      )
                                    : _c(
                                        "v-icon",
                                        { attrs: { color: "success" } },
                                        [_vm._v("mdi-chevron-up")]
                                      ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.menuList, function (item) {
                          return _c(
                            "v-list-item-group",
                            { key: item.tag },
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "tile",
                                  attrs: { id: "id" + item.tag },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadXml(item.tag)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.text))]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-data-table",
        {
          staticClass: "tbody",
          attrs: {
            headers: _vm.headers,
            items: _vm.items ? _vm.items : "",
            options: _vm.pagination,
            "items-per-page-options": _vm.pagination.page,
            "items-per-page": 5,
            "server-items-length": _vm.totalRubricas,
            page: _vm.pagination.page,
            "footer-props": {
              "items-per-page-options": [5, 10, 25, 50],
            },
          },
          on: {
            "update:options": function ($event) {
              _vm.pagination = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "item.detalhamento",
              fn: function ({ item }) {
                return [
                  _c("div", [
                    _c("strong", [_vm._v(" Tipo "), _c("br")]),
                    _vm._v(" " + _vm._s(item.tpRubrDadosrubrica) + " "),
                  ]),
                  _c("div", [
                    _c("strong", [_vm._v(" CP "), _c("br")]),
                    _vm._v(" " + _vm._s(item.codIncCPDadosrubrica) + " "),
                  ]),
                  _c("div", [
                    _c("strong", [_vm._v(" IRRF "), _c("br")]),
                    _vm._v(_vm._s(item.codIncIRRFDadosrubrica) + " "),
                  ]),
                  _c("div", [
                    _c("strong", [_vm._v(" FGTS "), _c("br")]),
                    _vm._v(" " + _vm._s(item.codIncFGTSDadosrubrica) + " "),
                  ]),
                  _c("div", [
                    _c("strong", [_vm._v(" Natureza "), _c("br")]),
                    _vm._v(_vm._s(item.natRubrDadosrubrica) + " "),
                  ]),
                ]
              },
            },
            {
              key: "item.companyName",
              fn: function ({ item }) {
                return [
                  _c("div", [
                    _c("strong", [_vm._v(" Empresa "), _c("br")]),
                    _vm._v(_vm._s(item.companyName) + " "),
                  ]),
                  _c("br"),
                  _c("div", [
                    _c("strong", [_vm._v(" Id. de Tabela "), _c("br")]),
                    _vm._v(_vm._s(item.ideTabRubrIderubrica) + " "),
                  ]),
                ]
              },
            },
            {
              key: "item.iniValidIderubrica",
              fn: function ({ item }) {
                return [
                  _c("div", [
                    _c("strong", [_vm._v(" Início: ")]),
                    _vm._v(
                      _vm._s(
                        _vm._f("date")(item.iniValidIderubrica, {
                          mask: "MM/YYYY",
                          showEmpty: true,
                        })
                      )
                    ),
                  ]),
                ]
              },
            },
            {
              key: "item.fimValidIderubrica",
              fn: function ({ item }) {
                return [
                  _c("div", [
                    _c("strong", [_vm._v(" Fim: ")]),
                    _vm._v(
                      _vm._s(
                        _vm._f("date")(item.fimValidIderubrica, {
                          mask: "MM/YYYY",
                          showEmpty: true,
                        })
                      )
                    ),
                  ]),
                ]
              },
            },
            {
              key: "item.status",
              fn: function ({ item }) {
                return [
                  _vm._v(" " + _vm._s(_vm._f("estados")(item.status)) + " "),
                ]
              },
            },
            {
              key: "item.action",
              fn: function ({ item }) {
                return [
                  _c(
                    "AGTooltip",
                    { attrs: { "tooltip-text": "Editar" } },
                    [
                      _c(
                        "v-btn",
                        { attrs: { icon: "", title: "Editar", id: "btnEdit" } },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "text-button",
                              attrs: { to: `/eSocial/rubricas/${item.id}` },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" mdi-pencil "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "no-data",
              fn: function () {
                return [
                  !_vm.dataOrNot.hasData &&
                  _vm.dataOrNot.isFromCard &&
                  !_vm.loading
                    ? _c("div", [
                        _vm.dataOrNot.name === "Conciliada" ||
                        _vm.dataOrNot.name === "Divergente"
                          ? _c("div", [
                              _vm._v(
                                " Não existem rubricas " +
                                  _vm._s(_vm.dataOrNot.name.toLowerCase()) +
                                  "s a serem exibidas "
                              ),
                            ])
                          : _c("div", [
                              _c("div", [
                                _vm._v(
                                  "Não existem rubricas " +
                                    _vm._s(_vm.dataOrNot.name.toLowerCase()) +
                                    " a serem exibidas"
                                ),
                              ]),
                            ]),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "top" },
            [
              _vm.loading
                ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                : _vm._e(),
            ],
            1
          ),
          _vm.loading
            ? _c("template", { slot: "body" }, [
                _c("tr", [
                  _c(
                    "td",
                    { style: _vm.customStyleSkeleton },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "loaderCss",
                        attrs: { "max-width": "67", type: "table-cell@1" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { style: _vm.customStyleSkeleton },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "loaderCss",
                        attrs: { "max-width": "156", type: "table-cell@1" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { style: _vm.customStyleSkeleton },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "loaderCss",
                        attrs: { "max-width": "306", type: "table-cell@1" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { style: _vm.customStyleSkeleton },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "loaderCss",
                        attrs: { "max-width": "180", type: "table-cell@1" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { style: _vm.customStyleSkeleton },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "loaderCss",
                        attrs: { "max-width": "114", type: "table-cell@1" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { style: _vm.customStyleSkeleton },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "loaderCss",
                        attrs: { "max-width": "105", type: "table-cell@1" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { style: _vm.customStyleSkeleton },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "loaderCss",
                        attrs: { "max-width": "118", type: "table-cell@1" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { style: _vm.customStyleSkeleton },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "loaderCss",
                        attrs: { "max-width": "127", type: "table-cell@1" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { style: _vm.customStyleSkeleton },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "loaderCss",
                        attrs: { "max-width": "73", type: "table-cell@1" },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }