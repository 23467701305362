<template>
  <v-card class="ma-4">
    <v-card-title class="pl-0 pr-0">
      <v-row>
        <v-col cols="12" sm="6">
          <TableSearch
            label="Pesquisar por descrição ou código"
            id="searchBarRubri"
            @input="onInputTableRubricaSearch"
            @click:clear="onClearTableRubricaSearch"
            :disabled="loading"
          >
          </TableSearch>
      </v-col>
        <v-col cols="12" sm="6" class="text-right">
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs, value }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                outlined
                color="success"
                elevation="0"
                id="btnDownload"
                :style="!value ? `border:1px solid #e7e7fa` : `border: 1px solid primary`"
              >
                <v-icon color="success" style="font-size: 18px; font-weight: 500; margin-right: 8px" id="idDownload"
                  >mdi-download-outline</v-icon
                >
                <span
                  style="font-size: 12px; font-weight: 400; letter-spacing: 0em; font-family: 'Roboto'"
                  id="btnexport"
                  >Exportar</span
                >
                <v-divider class="mx-2" style="height: 24px" vertical></v-divider>

                <v-icon v-if="!value" color="success">mdi-chevron-down</v-icon>
                <v-icon v-else color="success">mdi-chevron-up</v-icon>
              </v-btn>
            </template>
            <v-list
              ><v-list-item-group v-for="item in menuList" :key="item.tag">
                <v-list-item class="tile" @click="downloadXml(item.tag)" :id="'id' + item.tag">{{
                  item.text
                }}</v-list-item>
              </v-list-item-group></v-list
            >
          </v-menu>
        </v-col>
      </v-row>
      <!-- </v-col> -->
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items ? items : ''"
      :options.sync="pagination"
      :items-per-page-options="pagination.page"
      :items-per-page="5"
      :server-items-length="totalRubricas"
      :page="pagination.page"
      class="tbody"
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50],
      }"
    >
      <template slot="top"> <v-progress-linear indeterminate v-if="loading"></v-progress-linear></template>
      <template slot="body" v-if="loading">
        <tr>
          <td :style="customStyleSkeleton">
            <v-skeleton-loader class="loaderCss" max-width="67" type="table-cell@1"></v-skeleton-loader>
          </td>
          <td :style="customStyleSkeleton">
            <v-skeleton-loader class="loaderCss" max-width="156" type="table-cell@1"></v-skeleton-loader>
          </td>
          <td :style="customStyleSkeleton">
            <v-skeleton-loader class="loaderCss" max-width="306" type="table-cell@1"></v-skeleton-loader>
          </td>
          <td :style="customStyleSkeleton">
            <v-skeleton-loader class="loaderCss" max-width="180" type="table-cell@1"></v-skeleton-loader>
          </td>
          <td :style="customStyleSkeleton">
            <v-skeleton-loader class="loaderCss" max-width="114" type="table-cell@1"></v-skeleton-loader>
          </td>
          <td :style="customStyleSkeleton">
            <v-skeleton-loader class="loaderCss" max-width="105" type="table-cell@1"></v-skeleton-loader>
          </td>
          <td :style="customStyleSkeleton">
            <v-skeleton-loader class="loaderCss" max-width="118" type="table-cell@1"></v-skeleton-loader>
          </td>
          <td :style="customStyleSkeleton">
            <v-skeleton-loader class="loaderCss" max-width="127" type="table-cell@1"></v-skeleton-loader>
          </td>
          <td :style="customStyleSkeleton">
            <v-skeleton-loader class="loaderCss" max-width="73" type="table-cell@1"></v-skeleton-loader>
          </td>
        </tr>
      </template>
      <template v-slot:item.detalhamento="{ item }">
        <div>
          <strong> Tipo <br /> </strong> {{ item.tpRubrDadosrubrica }}
        </div>
        <div>
          <strong> CP <br /> </strong> {{ item.codIncCPDadosrubrica }}
        </div>
        <div>
          <strong> IRRF <br /> </strong>{{ item.codIncIRRFDadosrubrica }}
        </div>
        <div>
          <strong> FGTS <br /> </strong> {{ item.codIncFGTSDadosrubrica }}
        </div>
        <div>
          <strong> Natureza <br /> </strong>{{ item.natRubrDadosrubrica }}
        </div>
      </template>
      <template v-slot:item.companyName="{ item }">
        <div>
          <strong> Empresa <br /> </strong>{{ item.companyName }}
        </div>
        <br />
        <div>
          <strong> Id. de Tabela <br /></strong>{{ item.ideTabRubrIderubrica }}
        </div>
      </template>
      <template v-slot:item.iniValidIderubrica="{ item }">
        <div><strong> Início: </strong>{{ item.iniValidIderubrica | date({ mask: 'MM/YYYY', showEmpty: true }) }}</div>
      </template>
      <template v-slot:item.fimValidIderubrica="{ item }">
        <div><strong> Fim: </strong>{{ item.fimValidIderubrica | date({ mask: 'MM/YYYY', showEmpty: true }) }}</div>
      </template>
      <template v-slot:item.status="{ item }">
        {{ item.status | estados }}
      </template>
      <template v-slot:item.action="{ item }">
        <AGTooltip tooltip-text="Editar">
          <v-btn icon title="Editar" id="btnEdit">
            <router-link class="text-button" :to="`/eSocial/rubricas/${item.id}`">
              <v-icon small> mdi-pencil </v-icon>
            </router-link>
          </v-btn>
        </AGTooltip>
      </template>
      <template v-slot:no-data>
        <div v-if="!dataOrNot.hasData && dataOrNot.isFromCard && !loading">
          <div v-if="dataOrNot.name === 'Conciliada' || dataOrNot.name === 'Divergente'">
            Não existem rubricas {{ dataOrNot.name.toLowerCase() }}s a serem exibidas
          </div>
          <div v-else>
            <div>Não existem rubricas {{ dataOrNot.name.toLowerCase() }} a serem exibidas</div>
          </div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import eSocialService from '@/services/eSocialService.js';
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '@/main.js';

export default {
  name: 'TableRubricas',
  components: {
    Carousel: () => import('./Carousel.vue'),
    FiltroRubrica: () => import('./FiltroRubricas.vue'),
    SituationOverview: () => import('./SituationOverview.vue'),
    TotalForSituation: () => import('./TotalForSituation.vue'),
    NoData: () => import('../../indicadores/components/NoData.vue'),
    TableSearch: () => import('../../commons/TableSearch.vue'),
    AGTooltip: () => import('../../commons/AGTooltip.vue'),
  },

  data() {
    return {
      customStyleSkeleton:
        'text-align: start;color: #e7e7fa;font-family: "Roboto";font-weight: 700;font-size: 28px;letter-spacing: 1%;height: 51px;flex-direction: row;align-items: flex-start; border-left: 2px solid #ffffff',
      showIt: true,
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
      menuList: [
        { tag: 'null', text: 'Todas as rubricas' },
        { tag: 'PENDENTE_DE_VALIDACAO', text: 'Pendente de validação' },
        { tag: 'CONCILIADA', text: 'Conciliada' },
        { tag: 'PENDENTE_APROVACAO', text: 'Pendente de aprovação' },
        { tag: 'PENDENTE_PARAMETRIZACAO', text: 'Pendente de parametrização' },
        { tag: 'DIVERGENTE', text: 'Divergente' },
        { tag: 'REPROVADA', text: 'Reprovada' },
      ],
      headers: [
        {
          text: 'Cód.',
          value: 'codRubrIderubrica',
          sortable: false,
        },
        {
          text: 'Descrição',
          value: 'dscRubrDadosrubrica',
          width: '15%',
        },
        {
          text: 'Detalhamento',
          sortable: false,
          value: 'detalhamento',
        },
        {
          text: 'Empresa',
          sortable: false,
          value: 'companyName',
        },
        {
          text: 'Início da val.',
          sortable: false,
          value: 'iniValidIderubrica',
          width: '10%',
        },
        {
          text: 'Fim da val.',
          sortable: false,
          value: 'fimValidIderubrica',
          width: '10%',
        },
        {
          text: 'Alteração',
          value: 'dataRegistro',
          width: '10%',
        },
        {
          text: 'Situação',
          value: 'status',
          width: '10%',
        },
        {
          text: 'Ações',
          sortable: false,
          value: 'action',
        },
      ],
      companies: [],
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.rubricas && this.rubricas.length === 0) this.fetch();
        else this.fetchDebounce();
      },
    },
  },
  computed: {
    ...mapGetters({
      items: 'esocial/esocialMain/getRubricas',
      dscRubrDadosrubrica: 'esocial/esocialMain/dscRubrDadosrubrica',
      totalRubricas: 'esocial/esocialMain/getTotalRubricas',
      counters: 'esocial/esocialMain/getTotalForSituation',
      paginationFilter: 'esocial/esocialMain/pagination',
      loading: 'esocial/esocialMain/getLoading',
      dataOrNot: 'esocial/esocialMain/getDataOrNot',
      nrInscIdeempregador: 'esocial/esocialMain/getNrInscIdeempregador',
    }),
    pagination: {
      get: function () {
        return this.paginationFilter;
      },
      set: function (value) {
        this.setPagination(value);
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('esocial/esocialMain', ['changeFilter', 'setPagination', 'fetchDebounce']),
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },

    onInputTableRubricaSearch(val) {
        this.changeFilter({ prop: 'dscRubrDadosrubrica', value: val.toUpperCase() });
    },
    onClearTableRubricaSearch() {
        this.changeFilter({ prop: 'dscRubrDadosrubrica', value: '' });
    },

    open() {},

    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
    async downloadXml(status) {
      let message = 'Download iniciado. verifique o arquivo em seu computador.';
      this.showToast(message, 'success', 78);

      let cnpjs = [];
      this.nrInscIdeempregador.forEach((e) => {
        cnpjs.push(e.cnpj);
      });
      let filter = {
        cnpjs: cnpjs,
      };
      if (status && status != 'null') {
        filter.status = status;
      }
      let response = await eSocialService.rubricahistorico.download(filter);
      let blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Report.xlsx';
      link.click();
    },
  },
};
</script>
<style scoped>
.tbody {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1%;
  color: #575767;
}
::v-deep .tbody tr td {
  padding: 16px;
}
::v-deep .tbody tr td div strong {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 133%;
  color: #2a1c4f;
  letter-spacing: 0.01em;
}
#btnDownload::before {
  display: none;
}
::v-deep tbody tr:nth-of-type(odd) {
  background-color: #f9fafb;
}

.tile:hover {
  background: #e3ffee;
}
.tile:active {
  background: yellow;
}

::v-deep .v-data-table-header {
  background-color: #e7e7fa;
}

::v-deep .v-data-table-header tr th.text-start span {
  color: var(--v-primary-base);
  line-height: 18.62px;
  letter-spacing: 1%;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
}
.loaderCss {
  width: 100%;
  height: 15px;
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 200px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
.text-button {
  text-decoration: none;
}

@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}
</style>
